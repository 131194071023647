import Vue from 'vue';
import App from './App';
import router from './router';
import store from './Vuex/store';
import './otherJS/filters';
import Scrollspy from 'vue2-scrollspy';
import infiniteScroll from 'vue-infinite-scroll';
import {Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

import container from '@/layouts/container';
import nocontainer from "@/layouts/nocontainer";
import profile from "@/views/profile";
import Echo from "laravel-echo";
import {BACKEND_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY} from "@/config";
import {siteIsClose} from "@/otherJS/utils";
import Unavailable from "@/components/Unavailable";

// window.io = require('socket.io-client');
window.Pusher = require('pusher-js');

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);


//region Echo
let echoOption = {
    broadcaster: 'pusher',
    key: PUSHER_APP_KEY,
    cluster: PUSHER_APP_CLUSTER,
    forceTLC: true
};
const accessToken = localStorage.getItem('userAccessToken');
if (accessToken) {
    echoOption = Object.assign(echoOption, {
            authEndpoint: `${BACKEND_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
            },
        }
    );
}

export const ActiveEcho = new Echo(echoOption);
//endregion

Vue.component('container-layout', container);
Vue.component('nocontainer-layout', nocontainer);
Vue.component('profile-layout', profile);

Vue.config.productionTip = false;


Vue.use(infiniteScroll);
Vue.use(Scrollspy);
Vue.use(getAwesomeSwiper(SwiperClass));
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});



console.log(process.env.VUE_APP_SITE_IS_OPEN, 'process.env.VUE_APP_SITE_IS_OPEN')
console.log(siteIsClose(), 'siteIsClose()')
if (siteIsClose()) {
    //Приложение не работает

    new Vue(Unavailable).$mount('#app');

} else {
    //Приложение работает

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');

}
