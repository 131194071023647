<template>
  <header class="header" @mouseleave="closeList">
    <div class="header__bg"></div>
    <div class="container">
      <div class="header__wrapper">
        <router-link @mouseenter.native="closeList" class="header__logo" active-class="" exact-active-class=""
                     :to="{name: 'home'}">

          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px"
               viewBox="0 0 120 42.3" xml:space="preserve">
            <g>
	<g>
		<path fill="#FF321D" d="M70.2,24.8l-0.5,0.8c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
			c0-0.1-0.1-0.3-0.1-0.4l0.5-0.9c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2
			C69.7,24.3,69.9,24.6,70.2,24.8z"/>
    <circle fill="#FF321D" cx="66.2" cy="23.8" r="0.6"/>
    <path fill="#FF321D" d="M64.9,25.7c0.1,0,0.2,0,0.3-0.1l0.5,0.6c-0.3,0.3-0.5,0.8-0.5,1.3c0,1,0.8,1.9,1.9,1.9
			c0.7,0,1.3-0.4,1.6-0.9l1.3,0.5c0,0,0,0.1,0,0.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7l0.4-0.1c0.1,0.2,0.2,0.3,0.3,0.4
			l-0.3,0.7c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0-0.2-0.1-0.4-0.2-0.5l0.3-0.7
			c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.8,0.4-0.9,0.8l-0.3,0.1c-0.1-0.3-0.4-0.4-0.7-0.4
			c-0.2,0-0.5,0.1-0.6,0.3l-1.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7c0-0.8-0.5-1.5-1.2-1.7l0.3-1.2c0.1,0,0.1,0,0.2,0
			c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.5,0.3,1,0.8,1.2l-0.3,1.2c-0.1,0-0.3,0-0.4,0
			c-0.4,0-0.8,0.1-1.1,0.4l-0.5-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6C64.3,25.4,64.6,25.7,64.9,25.7z"
    />
    <circle fill="#FF321D" cx="63.9" cy="27.9" r="0.9"/>
    <circle fill="#FF321D" cx="66.2" cy="30.3" r="0.7"/>
    <circle fill="#FF321D" cx="68.5" cy="30.4" r="0.5"/>
    <circle fill="#FF321D" cx="68.9" cy="31.8" r="0.5"/>
    <circle fill="#FF321D" cx="68.7" cy="33.2" r="0.6"/>
    <circle fill="#FF321D" cx="67.6" cy="34.8" r="0.9"/>
    <path fill="#FF321D" d="M66.8,33.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.1,0-0.3,0.1-0.4,0.1l-1.3-0.8
			c0.1-0.1,0.1-0.2,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.2,0,0.4-0.1,0.6-0.2l1.3,0.8
			c0,0,0,0.1,0,0.2C66.2,33.1,66.4,33.4,66.8,33.4z"/>
    <circle fill="#FF321D" cx="63.9" cy="34.1" r="0.8"/>
    <circle fill="#FF321D" cx="64.4" cy="38.2" r="0.5"/>
    <path fill="#FF321D" d="M67.4,36.5c-0.2,0-0.4,0.1-0.4,0.3l-0.6,0.1c-0.1-0.3-0.4-0.6-0.7-0.6c-0.4,0-0.7,0.3-0.7,0.7
			c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.6-0.2,0.7-0.6l0.6-0.1c0.1,0.2,0.2,0.3,0.4,0.3c0.3,0,0.5-0.2,0.5-0.5S67.7,36.5,67.4,36.5z"/>
    <path fill="#FF321D" d="M64.2,39.6c-0.1,0-0.3,0-0.4,0.1l-0.4-0.5c0.1-0.1,0.1-0.2,0.1-0.4c0-0.3-0.2-0.6-0.5-0.6l0-0.9
			c0.6-0.1,1.1-0.6,1.1-1.2c0-0.1,0-0.3-0.1-0.4l1.2-0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6
			c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0.1,0,0.1l-1.2,0.7c-0.2-0.3-0.6-0.6-1.1-0.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.6,0.5,1.2,1.1,1.2l0,0.9
			c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.4,0.3,0.6,0.6,0.6c0.1,0,0.2,0,0.2-0.1l0.4,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.5,0.4,0.9,0.9,0.9
			c0.5,0,0.9-0.4,0.9-0.9C65.1,40,64.7,39.6,64.2,39.6z"/>
    <path fill="#FF321D" d="M72.1,34.6l1-1.9c0.1,0,0.1,0.1,0.2,0.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6
			c-0.3,0-0.6,0.3-0.6,0.6c0,0.1,0,0.2,0.1,0.3l-1.1,1.9c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0l-0.7-1.3c0.1-0.1,0.1-0.2,0.1-0.4
			c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.1,0,0.2,0,0.2-0.1l0.7,1.3C71,34.7,71,34.9,71,35.1
			c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7C72.3,34.9,72.2,34.7,72.1,34.6z"/>
    <circle fill="#FF321D" cx="73.9" cy="34.1" r="0.7"/>
    <path fill="#FF321D" d="M76.2,33.2c-0.4,0-0.7,0.3-0.7,0.7c0,0.2,0.1,0.4,0.2,0.5l-0.6,0.8c-0.1-0.1-0.3-0.1-0.4-0.1
			c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l0.6-0.8c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.3,0.7-0.7
			C76.9,33.6,76.6,33.2,76.2,33.2z"/>
    <circle fill="#FF321D" cx="77.8" cy="34.1" r="0.6"/>
    <path fill="#FF321D" d="M77.3,32.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.3,0.1-0.4,0.2L76,30.9
			c0.1-0.2,0.2-0.4,0.2-0.7c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.3,0,0.6-0.1,0.9-0.3l0.9,0.7
			c0,0.1,0,0.2,0,0.2C76.7,32.5,77,32.8,77.3,32.8z"/>
    <path fill="#FF321D" d="M75.4,25.7c0,0.3,0.1,0.5,0.2,0.7l-0.7,0.7c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0-0.6,0.3-0.6,0.6
			s0.3,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.3l1.5,0.4c0,0.4,0.3,0.6,0.6,0.6c0.1,0,0.2,0,0.2-0.1l0.8,1.1c0,0.1-0.1,0.2-0.1,0.3
			c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.6-0.6-0.6c-0.1,0-0.2,0-0.3,0.1l-0.8-1c0.1-0.1,0.1-0.2,0.1-0.4
			c0-0.4-0.3-0.6-0.6-0.6c-0.2,0-0.5,0.1-0.6,0.3l-1.5-0.4c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4l0.7-0.7c0.2,0.2,0.5,0.3,0.8,0.3
			c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C76,24.4,75.4,25,75.4,25.7z"/>
    <circle fill="#FF321D" cx="72.8" cy="26.1" r="0.7"/>
    <circle fill="#FF321D" cx="74.6" cy="24.2" r="0.9"/>
    <path fill="#FF321D" d="M71.4,21.6c0.2,0,0.4-0.1,0.6-0.2l1.3,0.8c0,0.1,0,0.1,0,0.2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
			s-0.3-0.6-0.6-0.6c-0.2,0-0.3,0.1-0.4,0.2l-1.3-0.8c0.1-0.1,0.1-0.3,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9
			S70.9,21.6,71.4,21.6z"/>
    <path fill="#FF321D" d="M72.3,19.5c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0-0.1l0.5-0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.4,0,0.6-0.3,0.6-0.6
			s-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6c0,0.1,0,0.1,0,0.2l-0.4,0.3c-0.1-0.2-0.3-0.3-0.5-0.3c-0.3,0-0.6,0.3-0.6,0.6
			C71.7,19.2,72,19.5,72.3,19.5z"/>
    <circle fill="#FF321D" cx="73.5" cy="19.9" r="0.6"/>
    <circle fill="#FF321D" cx="75.8" cy="22.8" r="0.6"/>
    <path fill="#FF321D" d="M77.4,22.8c0.2,0,0.4-0.1,0.5-0.2l0.9,0.4c0,0,0,0.1,0,0.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5
			c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.3,0.1-0.4,0.2L78,22.3c0,0,0-0.1,0-0.1c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6
			C76.8,22.5,77.1,22.8,77.4,22.8z"/>
    <circle fill="#FF321D" cx="78.6" cy="24.5" r="0.7"/>
    <circle fill="#FF321D" cx="79.7" cy="26.1" r="0.9"/>
    <circle fill="#FF321D" cx="81.1" cy="23.9" r="0.7"/>
    <circle fill="#FF321D" cx="80" cy="21.1" r="0.5"/>
    <path fill="#FF321D" d="M81.2,20.3c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.3-0.1-0.4l0.4-0.2c0.1,0.1,0.2,0.2,0.4,0.2
			c0.2,0,0.4-0.2,0.5-0.4l0.3,0c0.1,0.2,0.3,0.3,0.5,0.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0.2-0.5,0.4
			l-0.3,0c-0.1-0.2-0.3-0.3-0.5-0.3c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0.1L81.5,19c-0.1-0.1-0.2-0.1-0.4-0.1c-0.4,0-0.7,0.3-0.7,0.7
			S80.8,20.3,81.2,20.3z"/>
    <circle fill="#FF321D" cx="81.8" cy="31.3" r="0.9"/>
    <path fill="#FF321D" d="M82.9,34.1c0.1,0,0.2,0,0.3-0.1l0.4,0.4c-0.2,0.3-0.3,0.6-0.3,1c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8
			c0-0.5-0.2-0.9-0.5-1.2l0.6-0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.6-0.3,0.6-0.6c0-0.2-0.1-0.3-0.2-0.5l0.5-0.7c0.2,0.1,0.4,0.1,0.6,0.1
			c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.4,0.2,0.8,0.5,1l-0.5,0.7c-0.1,0-0.1,0-0.2,0
			c-0.4,0-0.6,0.3-0.6,0.6c0,0.2,0.1,0.3,0.2,0.4l-0.5,0.8c-0.3-0.2-0.7-0.4-1.1-0.4c-0.5,0-1,0.2-1.3,0.5l-0.3-0.4
			c0.1-0.1,0.1-0.2,0.1-0.4c0-0.4-0.3-0.6-0.6-0.6c-0.3,0-0.5,0.2-0.6,0.4l-1.5-0.1c0,0,0-0.1,0-0.1c0-0.7-0.6-1.2-1.2-1.2
			c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.5,0,1-0.3,1.2-0.8l1.5,0.1C82.3,33.9,82.6,34.1,82.9,34.1z"/>
    <circle fill="#FF321D" cx="81.5" cy="35.6" r="0.6"/>
    <path fill="#FF321D" d="M86.1,37.9c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0L85,38.5c-0.1-0.3-0.3-0.5-0.6-0.5c-0.1,0-0.3,0-0.4,0.1
			l-0.6-0.8c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.1,0
			l0.6,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.6-0.2,0.6-0.5l0.7-0.2c0.1,0.1,0.2,0.2,0.4,0.2
			c0.3,0,0.5-0.2,0.5-0.5C86.6,38.1,86.3,37.9,86.1,37.9z"/>
    <path fill="#FF321D" d="M83.5,30.4c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.6-0.6-0.6c0,0,0,0,0,0l-0.3-0.9
			c0.2-0.1,0.3-0.3,0.3-0.5c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.2-0.6,0.5l-2.1,0.2c-0.1-0.3-0.3-0.5-0.6-0.5
			c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.6-0.2,0.7-0.6l2.1-0.2c0.1,0.2,0.3,0.4,0.6,0.4c0,0,0,0,0,0l0.3,0.9
			C83.6,30,83.5,30.2,83.5,30.4z"/>
    <path fill="#FF321D" d="M81.5,26.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.3-0.1-0.5-0.3-0.7l0.5-0.9
			c0.1,0.1,0.2,0.1,0.3,0.1c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6c0,0.1,0,0.2,0.1,0.3l-0.6,0.9
			c-0.1,0-0.2-0.1-0.3-0.1C81.9,25.3,81.5,25.7,81.5,26.2z"/>
    <circle fill="#FF321D" cx="82.9" cy="23" r="0.6"/>
    <circle fill="#FF321D" cx="84.4" cy="25.7" r="0.5"/>
    <circle fill="#FF321D" cx="85.9" cy="27" r="1.2"/>
    <path fill="#FF321D" d="M85.8,30.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9S85,29.5,85,30c0,0.3,0.2,0.6,0.4,0.7l-0.1,0.7
			c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.2-0.1-0.3-0.2-0.4l0.2-0.8
			C85.7,30.8,85.8,30.9,85.8,30.9z"/>
    <circle fill="#FF321D" cx="89.1" cy="33.8" r="0.6"/>
    <circle fill="#FF321D" cx="87.9" cy="35.2" r="0.5"/>
    <circle fill="#FF321D" cx="87.7" cy="38.1" r="0.6"/>
    <circle fill="#FF321D" cx="89.5" cy="36.3" r="0.9"/>
    <path fill="#FF321D" d="M91.5,38.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.1,0,0.1,0,0.2l-2,0.3c-0.1-0.3-0.4-0.6-0.8-0.6
			c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9l2-0.4c0.2,0.5,0.6,0.8,1.2,0.8c0.7,0,1.3-0.6,1.3-1.3
			C92.7,38.6,92.1,38.1,91.5,38.1z"/>
    <path fill="#FF321D" d="M92.9,34.6c0-0.4-0.3-0.6-0.6-0.6c-0.1,0-0.2,0-0.3,0.1c-0.3-0.3-0.6-0.6-0.8-0.8c0.1-0.1,0.1-0.3,0.1-0.4
			c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.1,0,0.2,0,0.3-0.1c0.2,0.2,0.4,0.5,0.8,0.8
			c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.5,0.6l0,0.9c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6
			c0-0.3-0.2-0.5-0.5-0.6l0-1C92.7,35.1,92.9,34.9,92.9,34.6z"/>
    <circle fill="#FF321D" cx="92.3" cy="30.9" r="0.9"/>
    <path fill="#FF321D" d="M91.4,29.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.4,0.1-0.6,0.3l-1-0.8
			c0-0.1,0.1-0.2,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.5-0.1,0.7-0.3l1,0.8
			c0,0.1,0,0.1,0,0.2C90.7,29.3,91,29.6,91.4,29.6z"/>
    <circle fill="#FF321D" cx="91.1" cy="26.4" r="0.6"/>
    <path fill="#FF321D" d="M88.9,23.6c0.5,0,0.9-0.2,1.3-0.5l1,0.8c-0.1,0.2-0.2,0.3-0.2,0.6l-1.7,0.1c-0.1-0.2-0.3-0.3-0.5-0.3
			c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.4-0.2,0.5-0.4l1.7-0.1c0.1,0.4,0.5,0.7,0.9,0.7c0.5,0,0.9-0.4,0.9-0.9
			c0-0.5-0.4-0.9-0.9-0.9c-0.2,0-0.3,0-0.5,0.1l-1-0.9c0.2-0.3,0.3-0.7,0.3-1.1c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8
			S87.9,23.6,88.9,23.6z"/>
    <path fill="#FF321D" d="M93.2,20.6C93.2,20.6,93.2,20.6,93.2,20.6l-0.5,0.1c-0.1-0.2-0.3-0.4-0.6-0.4c-0.4,0-0.6,0.3-0.6,0.6
			c0,0.4,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6l0.5-0.1c0.1,0.3,0.4,0.6,0.8,0.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
			C93.6,19.8,93.2,20.2,93.2,20.6z"/>
    <circle fill="#FF321D" cx="93.1" cy="22.6" r="0.7"/>
    <circle fill="#FF321D" cx="90.5" cy="19.2" r="0.7"/>
    <path fill="#FF321D" d="M91,18.1c0.1,0,0.2,0,0.3-0.1l0.8,0.5c0,0.1,0,0.1,0,0.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
			c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.4,0.1-0.5,0.2l-0.8-0.5c0,0,0,0,0,0c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4
			C90.5,17.9,90.7,18.1,91,18.1z"/>
    <circle fill="#FF321D" cx="90" cy="16.2" r="0.6"/>
    <circle fill="#FF321D" cx="93.6" cy="27.3" r="0.6"/>
    <circle fill="#FF321D" cx="93.8" cy="25.1" r="0.6"/>
    <path fill="#FF321D" d="M95.3,22.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.2-0.1-0.3-0.2-0.4
			l0.5-0.7c0.1,0.1,0.3,0.1,0.5,0.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.4,0.2,0.8,0.6,1.1
			l-0.5,0.7C95.5,22.8,95.4,22.8,95.3,22.8z"/>
    <circle fill="#FF321D" cx="97.9" cy="24.1" r="0.7"/>
    <circle fill="#FF321D" cx="96.3" cy="26.1" r="0.9"/>
    <path fill="#FF321D" d="M98.4,26.8c-0.7,0-1.3,0.6-1.3,1.3c0,0,0,0.1,0,0.1l-1.4,0.3c-0.1-0.2-0.3-0.3-0.5-0.3
			c-0.4,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6c0,0,0,0,0-0.1l1.4-0.3c0.2,0.5,0.7,0.9,1.3,0.9
			c0.7,0,1.3-0.6,1.3-1.3C99.7,27.4,99.1,26.8,98.4,26.8z"/>
    <circle fill="#FF321D" cx="93.6" cy="29.4" r="0.6"/>
    <circle fill="#FF321D" cx="96.3" cy="31.1" r="0.7"/>
    <circle fill="#FF321D" cx="98.9" cy="30.6" r="0.6"/>
    <path fill="#FF321D" d="M96.8,35.8c0.4,0,0.7-0.3,0.7-0.7c0-0.2-0.1-0.4-0.2-0.5l0.3-1l0,0c0.5,0,0.8-0.4,0.8-0.8
			c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.3,0.2,0.6,0.5,0.8l-0.2,0.9c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0.1l-1.2-1.2
			c0.3-0.2,0.4-0.6,0.4-0.9c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.2,0,0.4,0,0.5-0.1l1.3,1.3
			c-0.1,0.1-0.1,0.2-0.1,0.3C96.1,35.5,96.4,35.8,96.8,35.8z"/>
    <circle fill="#FF321D" cx="94.5" cy="35.9" r="0.9"/>
    <circle fill="#FF321D" cx="93.5" cy="38" r="0.5"/>
    <circle fill="#FF321D" cx="95" cy="38.8" r="0.8"/>
    <path fill="#FF321D" d="M99.9,37.4c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0,0.1,0,0.2l-0.5,0.3c-0.1-0.1-0.3-0.2-0.4-0.2
			c-0.2,0-0.3,0.1-0.5,0.2l-0.8-0.4c0,0,0,0,0,0c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5
			c0.1,0,0.3-0.1,0.3-0.2l0.8,0.3c0,0.1,0,0.2,0,0.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3l0.5-0.3
			c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.5-0.5C100.4,37.7,100.2,37.4,99.9,37.4z"/>
    <circle fill="#FF321D" cx="98" cy="36.8" r="0.5"/>
    <circle fill="#FF321D" cx="98.9" cy="35.3" r="0.6"/>
    <circle fill="#FF321D" cx="98.9" cy="34" r="0.5"/>
    <circle fill="#FF321D" cx="100.5" cy="36.3" r="0.9"/>
    <path fill="#FF321D" d="M102.6,37l-0.2-1c0.2-0.1,0.3-0.3,0.3-0.5c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6c0,0.3,0.2,0.5,0.5,0.6
			l0.1,0.9c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5C102.9,37.2,102.8,37.1,102.6,37z"/>
    <path fill="#FF321D" d="M101.2,33.8c-0.1,0-0.1,0-0.2,0l-0.4-0.5c0.1-0.1,0.2-0.3,0.2-0.5c0-0.3-0.2-0.5-0.4-0.7l0.4-1.3
			c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.2,0.1,0.4,0.3,0.5l-0.4,1.3c0,0,0,0-0.1,0
			c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.1,0,0.2,0,0.3-0.1l0.4,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.3,0.6,0.6,0.6
			c0.3,0,0.6-0.3,0.6-0.6C101.8,34,101.5,33.8,101.2,33.8z"/>
    <circle fill="#FF321D" cx="101.4" cy="28.6" r="0.7"/>
    <circle fill="#FF321D" cx="103.5" cy="28.8" r="0.6"/>
    <circle fill="#FF321D" cx="100.5" cy="26.6" r="0.5"/>
    <path fill="#FF321D" d="M102.8,27.1c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.4,0-0.7,0.2-0.8,0.5l-1.7-0.7c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0.4-0.3,0.9-0.6,1.4-1c0.2,0.2,0.6,0.4,0.9,0.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
			c-0.7,0-1.3,0.6-1.3,1.3c0,0.2,0.1,0.5,0.2,0.7c-0.5,0.4-1,0.7-1.3,1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.3-0.6,0.6
			s0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2l1.8,0.8C102,26.7,102.4,27.1,102.8,27.1z"/>
    <circle fill="#FF321D" cx="98.8" cy="20.8" r="0.5"/>
    <path fill="#FF321D" d="M99.5,20.2c0.1,0,0.1,0,0.1,0l0.2,0.6c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5l-0.4,0.3
			c-0.1-0.1-0.2-0.2-0.4-0.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5l0.6-0.4c0.1,0,0.2,0.1,0.3,0.1
			c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0-0.1,0l-0.3-0.6c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.7-0.7-0.7
			c-0.4,0-0.7,0.3-0.7,0.7S99.2,20.2,99.5,20.2z"/>
    <circle fill="#FF321D" cx="100.3" cy="17.8" r="0.6"/>
    <circle fill="#FF321D" cx="101.6" cy="20.1" r="0.6"/>
    <path fill="#FF321D" d="M103.1,19c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.3,0,0.5-0.2,0.6-0.5l1.9-0.3c0.2,0.4,0.6,0.8,1.1,0.8
			c0.6,0,1.1-0.5,1.1-1.1c0-0.3-0.1-0.6-0.3-0.8l0.3-0.5c0,0,0.1,0,0.1,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
			c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0.1,0.3,0.1,0.4l-0.3,0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0.1l-1.9,0.3
			C103.5,19.1,103.4,19,103.1,19z"/>
    <circle fill="#FF321D" cx="102.4" cy="17.4" r="0.8"/>
    <path fill="#FF321D" d="M103.9,17.2c0.4,0,0.6-0.3,0.6-0.6c0,0,0-0.1,0-0.1l0.3-0.3c0.1,0.1,0.2,0.1,0.4,0.1
			c0.4,0,0.8-0.3,0.8-0.8c0-0.2-0.1-0.4-0.2-0.5l0.7-1.2c0.2,0.1,0.4,0.1,0.6,0.1c1,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.2
			l0.6-0.9c0.1,0,0.2,0.1,0.3,0.1c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.2,0.1,0.4,0.2,0.5l-0.6,0.9
			c-0.3-0.2-0.6-0.3-1-0.3c-1,0-1.7,0.8-1.7,1.7c0,0.6,0.3,1.2,0.8,1.5l-0.7,1.1c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0-0.8,0.3-0.8,0.8
			c0,0.2,0.1,0.3,0.1,0.5l-0.2,0.2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.6,0.3-0.6,0.6C103.3,16.9,103.6,17.2,103.9,17.2z"/>
    <circle fill="#FF321D" cx="104.5" cy="21.8" r="0.6"/>
    <circle fill="#FF321D" cx="106.2" cy="21" r="0.6"/>
    <circle fill="#FF321D" cx="105.7" cy="23.4" r="0.9"/>
    <circle fill="#FF321D" cx="107.1" cy="24.9" r="0.5"/>
    <circle fill="#FF321D" cx="106.8" cy="26.5" r="0.8"/>
    <circle fill="#FF321D" cx="106.7" cy="28.7" r="0.5"/>
    <circle fill="#FF321D" cx="105.9" cy="33.2" r="0.6"/>
    <circle fill="#FF321D" cx="106" cy="35.1" r="0.5"/>
    <circle fill="#FF321D" cx="108" cy="35.1" r="1.1"/>
    <circle fill="#FF321D" cx="109.4" cy="33.7" r="0.4"/>
    <circle fill="#FF321D" cx="109.6" cy="30.5" r="0.5"/>
    <circle fill="#FF321D" cx="110.5" cy="31.2" r="0.6"/>
    <path fill="#FF321D" d="M110.9,34.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.1,0-0.2,0-0.3,0.1l-1.3-0.7
			c0-0.1,0.1-0.2,0.1-0.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.1,0.5-0.2l1.3,0.7
			c0,0.1-0.1,0.2-0.1,0.2C110.3,33.9,110.6,34.2,110.9,34.2z"/>
    <circle fill="#FF321D" cx="111.7" cy="32.6" r="0.5"/>
    <path fill="#FF321D" d="M110.4,22.5c0-0.7-0.4-1.3-1-1.5l0.4-1.1c0,0,0,0,0.1,0c0.4,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6
			c-0.4,0-0.6,0.3-0.6,0.6c0,0.2,0.1,0.4,0.3,0.5l-0.4,1.1c-0.1,0-0.3,0-0.4,0c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7
			C109.6,24.2,110.4,23.4,110.4,22.5z"/>
    <path fill="#FF321D" d="M113.7,31.3c0-0.3-0.2-0.5-0.5-0.5c-0.1,0-0.2,0-0.3,0.1l-0.5-0.7c0.1-0.1,0.1-0.2,0.1-0.3
			c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l0.6,0.7c0,0.1,0,0.1,0,0.2
			c0,0.3,0.2,0.5,0.5,0.5S113.7,31.5,113.7,31.3z"/>
    <circle fill="#FF321D" cx="113.6" cy="32.5" r="0.3"/>
    <circle fill="#FF321D" cx="114.6" cy="33.5" r="0.6"/>
    <path fill="#FF321D" d="M112.4,36.7L112.4,36.7l-0.2-0.4c0.2-0.2,0.4-0.4,0.4-0.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
			c0,0.5,0.4,0.9,0.9,0.9c0.1,0,0.2,0,0.2,0l0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.2,0.3l-0.3,0.8
			c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l0.4-0.9
			c0.2-0.1,0.3-0.2,0.3-0.4C112.8,36.9,112.6,36.7,112.4,36.7z"/>
    <circle fill="#FF321D" cx="110.9" cy="37" r="0.5"/>
    <circle fill="#FF321D" cx="110.2" cy="11.4" r="0.5"/>
    <circle fill="#FF321D" cx="109.8" cy="14.6" r="0.4"/>
    <circle fill="#FF321D" cx="107.9" cy="14.9" r="0.6"/>
    <circle fill="#FF321D" cx="109.8" cy="16.5" r="0.8"/>
    <circle fill="#FF321D" cx="108.9" cy="18.1" r="0.5"/>
    <circle fill="#FF321D" cx="112.1" cy="16.1" r="0.5"/>
    <circle fill="#FF321D" cx="111.8" cy="17.5" r="0.5"/>
    <circle fill="#FF321D" cx="115.7" cy="24.7" r="0.9"/>
    <path fill="#FF321D" d="M84.2,22c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6
			c0.1,0,0.2,0,0.2,0l0.8,0.7c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.2,0,0.2-0.1l0.4,0.4c-0.1,0.1-0.2,0.3-0.2,0.5
			c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.2,0-0.3,0-0.4,0.1l-0.4-0.4c0-0.1,0-0.1,0-0.2
			c0-0.3-0.2-0.5-0.5-0.5c-0.1,0-0.2,0-0.2,0.1L84.2,22z"/>
    <path fill="#FF321D" d="M110.7,12.8c-0.1-0.1-0.2-0.2-0.4-0.2c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.1,0,0.1,0,0.2,0
			c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8
			c-0.2,0-0.4,0.1-0.5,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.2-0.1,0.4-0.1,0.6-0.2
			c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C111.1,12.6,110.8,12.7,110.7,12.8z
			"/>
    <path fill="#FF321D" d="M112.3,19.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6
			c0,0,0.1,0,0.1,0l0.6,0.8c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.5,0.4,0.8,0.8,0.8c0.3,0,0.5-0.1,0.6-0.3l0.6,0.3c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2,0.1,0.3l-0.3,0.4c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.1,0-0.2-0.1-0.3
			l0.3-0.4c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.1,0-0.3,0.1-0.4,0.2l-0.6-0.3c0-0.1,0-0.2,0-0.3
			c0-0.5-0.4-0.8-0.8-0.8c-0.1,0-0.2,0-0.3,0.1L112.3,19.4z"/>
    <path fill="#FF321D" d="M106,30.9c0.1,0.1,0.3,0.2,0.5,0.2c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.1,0-0.2,0-0.3,0
			l-0.9-1.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.3-0.2-0.6-0.5-0.7l-0.1-0.8c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.3-0.7-0.7-0.7
			c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7l0.1,0.8c-0.3,0.1-0.6,0.4-0.6,0.7c0,0.4,0.3,0.7,0.7,0.7c0.1,0,0.2,0,0.2,0
			l0.9,1.3c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.2,0.1,0.3l-0.8,0.3c-0.3-0.6-0.9-1.1-1.6-1.1c-1,0-1.8,0.8-1.8,1.8
			c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8c0-0.1,0-0.3,0-0.4L106,30.9z"/>
	</g>
              <g>
		<g>
			<path fill="#FFFFFF" d="M46.4,1.6c0.3,0,0.6-0.1,0.9-0.1c0.3,0,0.7-0.1,1-0.1c0.3,0,0.7,0,1-0.1c0.3,0,0.6,0,0.9,0
				c0.6,0,1.3,0.1,1.9,0.2c0.6,0.1,1.2,0.3,1.6,0.6c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.4,1,0.4,1.7c0,1-0.3,1.9-0.8,2.5
				c-0.5,0.7-1.2,1.1-2.1,1.4l0.9,0.6l3.1,4.7h-3.1l-3.1-4.9L49,8.9V14h-2.7V1.6z M50.4,3.6c-0.3,0-0.5,0-0.8,0
				c-0.3,0-0.5,0-0.6,0.1v3.5h1.1c0.8,0,1.3-0.1,1.8-0.4c0.4-0.3,0.7-0.8,0.7-1.5c0-0.5-0.2-0.9-0.6-1.2C51.6,3.7,51.1,3.6,50.4,3.6
				z"/>
      <path fill="#FFFFFF" d="M60.7,4.6v5.2c0,0.8,0.1,1.3,0.3,1.7c0.2,0.4,0.6,0.5,1.2,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.6-0.6,0.8-1
				v-6h2.5v6.5c0,0.5,0,1,0.1,1.5c0.1,0.5,0.1,1,0.2,1.4h-1.8l-0.5-1.5h-0.2c-0.3,0.5-0.8,0.9-1.3,1.2c-0.6,0.3-1.2,0.5-2,0.5
				c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.7-0.3-1-0.7c-0.3-0.3-0.5-0.7-0.6-1.2c-0.1-0.5-0.2-1.1-0.2-1.9V4.6H60.7z"/>
      <path fill="#FFFFFF" d="M69.6,11.6c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.5,0.1,0.8,0.2
				c0.3,0,0.5,0.1,0.8,0.1c0.4,0,0.7-0.1,1-0.2c0.2-0.1,0.3-0.4,0.3-0.7c0-0.3-0.2-0.5-0.5-0.6c-0.3-0.1-0.7-0.3-1.1-0.4
				c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.6-0.5-0.8-0.9c-0.2-0.4-0.3-0.8-0.3-1.4c0-0.9,0.3-1.7,0.9-2.2
				c0.6-0.5,1.4-0.7,2.5-0.7c0.7,0,1.4,0.1,2,0.2c0.6,0.1,1.1,0.3,1.5,0.4l-0.5,1.9c-0.3-0.1-0.7-0.2-1.2-0.4
				c-0.5-0.1-1-0.2-1.5-0.2c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.7c0,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0.3,1.2,0.4
				c0.3,0.1,0.7,0.2,1,0.3c0.4,0.1,0.7,0.3,1,0.5c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.3,0.8,0.3,1.3c0,1-0.3,1.8-1,2.3
				c-0.7,0.5-1.6,0.8-2.9,0.8c-0.7,0-1.4-0.1-2-0.2c-0.6-0.1-1.1-0.3-1.5-0.5L69.6,11.6z"/>
      <path fill="#FFFFFF" d="M78.3,11.6c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.5,0.1,0.8,0.2
				c0.3,0,0.5,0.1,0.8,0.1c0.4,0,0.7-0.1,1-0.2c0.2-0.1,0.3-0.4,0.3-0.7c0-0.3-0.2-0.5-0.5-0.6c-0.3-0.1-0.7-0.3-1.1-0.4
				c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.6-0.5-0.8-0.9c-0.2-0.4-0.3-0.8-0.3-1.4c0-0.9,0.3-1.7,0.9-2.2
				c0.6-0.5,1.4-0.7,2.5-0.7c0.7,0,1.4,0.1,2,0.2c0.6,0.1,1.1,0.3,1.5,0.4l-0.5,1.9c-0.3-0.1-0.7-0.2-1.2-0.4
				c-0.5-0.1-1-0.2-1.5-0.2c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.7c0,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.7,0.3,1.2,0.4
				c0.3,0.1,0.7,0.2,1,0.3c0.4,0.1,0.7,0.3,1,0.5c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.3,0.8,0.3,1.3c0,1-0.3,1.8-1,2.3
				c-0.7,0.5-1.6,0.8-2.9,0.8c-0.7,0-1.4-0.1-2-0.2c-0.6-0.1-1.1-0.3-1.5-0.5L78.3,11.6z"/>
      <path fill="#FFFFFF" d="M86.9,2.2c0-0.4,0.1-0.7,0.4-1C87.6,1,88,0.9,88.5,0.9c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.4,0.6,0.4,0.9
				c0,0.3-0.1,0.6-0.4,0.9c-0.3,0.3-0.7,0.4-1.2,0.4c-0.5,0-0.9-0.1-1.2-0.4C87,2.9,86.9,2.6,86.9,2.2z M87.3,4.6h2.5V14h-2.5V4.6z"
      />
      <path fill="#FFFFFF" d="M92.5,5.2c0.5-0.2,1.2-0.4,2-0.6c0.8-0.2,1.6-0.2,2.4-0.2c0.7,0,1.2,0.1,1.7,0.2c0.4,0.2,0.8,0.4,1.1,0.7
				c0.3,0.3,0.5,0.6,0.6,1.1c0.1,0.4,0.2,0.9,0.2,1.4c0,0.6,0,1.2-0.1,1.8c0,0.6-0.1,1.2-0.1,1.9c0,0.5,0,0.9,0.1,1.3
				c0.1,0.4,0.2,0.8,0.3,1.2h-2l-0.4-1.4H98c-0.3,0.4-0.7,0.8-1.2,1.1c-0.5,0.3-1.1,0.4-1.8,0.4c-0.5,0-0.9-0.1-1.2-0.2
				c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-1.1,0.4-1.9,1.1-2.4c0.7-0.5,1.8-0.8,3.2-0.8
				c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0-0.3,0.1-0.5,0.1-0.6c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.7-0.3-1.3-0.3
				c-0.6,0-1.1,0.1-1.7,0.2c-0.5,0.1-1,0.3-1.4,0.5L92.5,5.2z M95.8,12.1c0.6,0,1-0.1,1.3-0.4c0.3-0.3,0.6-0.5,0.7-0.8V9.8
				c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.1-1.4,0.3
				c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.1,0.8,0.4,1C95.1,12,95.4,12.1,95.8,12.1z"/>
		</g>
	</g>
              <path fill="#FF321D" d="M6.8,16.9c0.3,0,0.6-0.1,0.9-0.1c0.3,0,0.7,0,1.1-0.1c0.4,0,0.7,0,1.1,0c0.3,0,0.7,0,0.9,0
		c1.2,0,2.2,0.2,3.1,0.5c0.9,0.3,1.6,0.8,2.1,1.3c0.5,0.6,0.9,1.2,1.2,2c0.3,0.8,0.4,1.6,0.4,2.5c0,0.8-0.1,1.6-0.4,2.4
		c-0.2,0.8-0.6,1.5-1.2,2.1c-0.5,0.6-1.3,1.1-2.1,1.5c-0.9,0.4-2,0.6-3.3,0.6c-0.2,0-0.5,0-0.8,0c-0.4,0-0.7,0-1.1,0
		c-0.4,0-0.7,0-1.1-0.1c-0.3,0-0.6,0-0.7-0.1V16.9z M10.9,19c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5,0-0.7,0v8.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.8,0,1.4-0.1,1.9-0.4c0.5-0.2,0.9-0.6,1.2-0.9c0.3-0.4,0.5-0.8,0.6-1.3
		c0.1-0.5,0.2-1,0.2-1.6c0-0.4-0.1-0.9-0.2-1.4c-0.1-0.5-0.3-0.9-0.6-1.3c-0.3-0.4-0.7-0.7-1.1-1C12.3,19.2,11.7,19,10.9,19z"/>
              <path fill="#FF321D" d="M18.9,24.7c0-1.6,0.4-2.8,1.2-3.7c0.8-0.9,2-1.3,3.5-1.3c0.8,0,1.5,0.1,2.1,0.4s1.1,0.6,1.5,1
		c0.4,0.4,0.7,1,0.9,1.6c0.2,0.6,0.3,1.3,0.3,2c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.6-0.5,1.1-0.9,1.6c-0.4,0.4-0.9,0.8-1.5,1
		c-0.6,0.2-1.3,0.3-2,0.3c-0.8,0-1.5-0.1-2.1-0.4c-0.6-0.3-1.1-0.6-1.5-1c-0.4-0.4-0.7-1-0.9-1.6C19,26.1,18.9,25.4,18.9,24.7z
		M21.5,24.7c0,0.4,0,0.8,0.1,1.1c0.1,0.3,0.2,0.6,0.4,0.9c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.2,0.6,0.2,1,0.2c1.5,0,2.2-1,2.2-2.9
		c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.2-0.7-0.4-0.9c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.7,0-1.2,0.2-1.6,0.7
		C21.7,23,21.5,23.7,21.5,24.7z"/>
              <path fill="#FF321D" d="M29.4,20h1.4v-1.3l2.5-0.7v2h2.5v2.1h-2.5v3.3c0,0.8,0.1,1.3,0.2,1.6c0.1,0.3,0.4,0.5,0.9,0.5
		c0.3,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.6-0.2l0.5,1.9c-0.4,0.2-0.8,0.3-1.3,0.4c-0.5,0.1-1,0.2-1.4,0.2c-0.9,0-1.6-0.2-2-0.7
		c-0.4-0.5-0.6-1.2-0.6-2.2v-4.7h-1.4V20z"/>
              <path fill="#FF321D" d="M37.7,20.7c0.5-0.2,1.2-0.4,2-0.6c0.8-0.2,1.6-0.2,2.4-0.2c0.7,0,1.2,0.1,1.7,0.2c0.4,0.2,0.8,0.4,1.1,0.7
		c0.3,0.3,0.5,0.6,0.6,1.1c0.1,0.4,0.2,0.9,0.2,1.4c0,0.6,0,1.2-0.1,1.8c0,0.6-0.1,1.2-0.1,1.9c0,0.5,0,0.9,0.1,1.3
		c0.1,0.4,0.2,0.8,0.3,1.2h-2L43.3,28h-0.2c-0.3,0.4-0.7,0.8-1.2,1.1c-0.5,0.3-1.1,0.4-1.8,0.4c-0.5,0-0.9-0.1-1.2-0.2
		c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-1.1,0.4-1.9,1.1-2.4c0.7-0.5,1.8-0.8,3.2-0.8
		c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0-0.3,0.1-0.5,0.1-0.6c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.7-0.3-1.3-0.3
		c-0.6,0-1.1,0.1-1.7,0.2c-0.5,0.1-1,0.3-1.4,0.5L37.7,20.7z M40.9,27.5c0.6,0,1-0.1,1.3-0.4c0.3-0.3,0.6-0.5,0.7-0.8v-1.1
		c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.1-1.4,0.3
		c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.1,0.8,0.4,1C40.2,27.4,40.5,27.5,40.9,27.5z"/>
              <path fill="#FFFFFF" d="M55.9,20c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.5,1.2-0.9,1.8c-0.4,0.6-0.8,1.1-1.2,1.7
		c-0.4,0.5-0.9,1.1-1.3,1.5l-1,0.7v0.1l1.4-0.3h3.7v2.1h-8.2v-1.3c0.3-0.3,0.7-0.7,1-1c0.4-0.4,0.8-0.8,1.2-1.2
		c0.4-0.4,0.8-0.9,1.2-1.4c0.4-0.5,0.7-0.9,1-1.4c0.3-0.5,0.5-0.9,0.7-1.4c0.2-0.4,0.3-0.9,0.3-1.2c0-0.5-0.1-0.8-0.4-1.2
		c-0.3-0.3-0.7-0.5-1.4-0.5c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7L48,18c0.5-0.4,1.1-0.7,1.7-1c0.6-0.3,1.4-0.4,2.3-0.4
		c0.5,0,1.1,0.1,1.5,0.2c0.5,0.1,0.9,0.4,1.2,0.6c0.3,0.3,0.6,0.6,0.8,1C55.8,18.9,55.9,19.4,55.9,20z"/>
              <polygon fill="#FF321D"
                       points="20.4,1.7 16.8,1.7 16.8,6.6 20.4,6.6 20.4,13.8 25.4,13.8 25.4,6.6 29,6.6 29,1.7 25.4,1.7 	"/>
              <polygon fill="#FF321D"
                       points="7.1,1.7 3.5,1.7 3.5,6.6 7.1,6.6 7.1,13.8 12,13.8 12,6.6 15.6,6.6 15.6,1.7 12,1.7 	"/>
              <polygon fill="#FF321D" points="39.4,1.7 35.2,6.5 35.2,1.7 30.2,1.7 30.2,13.8 35.2,13.8 35.2,8.5 39.4,13.8 45.1,13.8 39.9,7.5
		45.1,1.7 	"/>
</g>
          </svg>
        </router-link>
        <nav ref="nav" class="header__nav">
          <ul>
            <li>
              <div @mouseenter="openList" ref="dropdownButton"
                   @click.self="$router.push({ name: 'allTournaments'})"
                   :class="[{active: tournamentsListVisible}, 'header-dropdown-button']">
                турниры
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6.5L8 10.5L4 6.5" stroke="#EAEAEA" stroke-linejoin="round"/>
                </svg>
              </div>
            </li>
            <li @mouseenter="closeList" v-for="link in navItems.slice(2)" :key="link.path">
              <router-link :to="{name: link.path}" class="header__link" exact-active-class="active">{{
                  link.label
                }}
              </router-link>
            </li>
          </ul>
        </nav>
        <!--        <div class="header-partners">
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo1.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo2.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo3.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo4.png')" alt="#">
                  </div>
                </div>-->
        <div class="profile-area">
          <search-input/>

          <header-profile v-if="AUTH.isAuthenticate"></header-profile>
          <div class="login-buttons" v-else>
            <button class="filled__yellow" @click.stop="showUserAuthModals">Войти</button>
          </div>
        </div>
        <media :query="{maxWidth: 1100}">
          <div class="more" @click="toggleStatusMobileNav">
            <div class="more_btn more_btn__mobile" :class="{active: this.GET_STATUS_MOBILE_NAV}">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </media>
      </div>
    </div>
    <div ref="disciplinesList" class="header-tournaments-list">
      <div class="list-wrapper" v-if="dataReady">
        <router-link :to="{name: 'sgl-events', params: {id: game.id}}" class="discipline-logo"
                     v-for="game in this.GET_TOURNAMENT_GROUPS" :key="game.id"
                     active-class=""
                     exact-active-class="">
          <img :src="GET_SPONSOR_GAMES.find(el => String(el.id) === String(game.gameInfo.id)).logo" alt="#">
        </router-link>
      </div>
    </div>

    <media :query="{maxWidth: 1100}">
      <div class="mobile_nav" :class="{active: this.GET_STATUS_MOBILE_NAV}">
        <div class="container">
          <search-input/>
          <nav>
            <ul>
              <li @click="closeMobileNav" v-for="link in navItems" :key="link.path">
                <router-link :to="{name: link.path}" class="header__link" exact-active-class="active">{{
                    link.label
                  }}
                </router-link>
              </li>
            </ul>
          </nav>
          <media :query="{maxWidth: 580}">
            <div class="divider" v-if="AUTH.isAuthenticate"></div>
          </media>
          <media :query="{maxWidth: 580}">
            <nav v-if="AUTH.isAuthenticate">
              <ul>
                <li @click="closeMobileNav">
                  <router-link :to="{name: 'user', params: {id: USER.id}}" class="header__link"
                               exact-active-class="active">Профиль
                  </router-link>
                </li>
                <!--                <li @click="closeMobileNav">-->
                <!--                  <router-link to="/teams/create" class="header__link" exact-active-class="active">Создать команду-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <li @click="closeMobileNav">
                  <div class="header__link" @click="LOGG_OUT">Выйти из аккаунта</div>
                </li>
              </ul>
            </nav>
          </media>
        </div>
      </div>
    </media>

    <login-modal
        v-if="AUTH.isAuthOpen"
    />
  </header>
</template>

<script>
import searchInput from "@/components/UIElements/InputSearch";
import HeaderProfile from "@/components/UIElements/headerProfile";
import Media from 'vue-media';
import LoginModal from "@/components/modals/login-modal";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "headerMain",
  components: {
    HeaderProfile,
    searchInput,
    Media,
    LoginModal
  },
  data() {
    return {
      tournamentsListVisible: false,
      dataReady: false,
      navItems: [
        {
          path: 'home',
          label: 'Главная'
        },
        {
          path: 'allTournaments',
          label: 'Турниры'
        },
        // {path: 'news', label: 'Новости'},
        // {path: 'videos', label: 'Видео'},
        {
          path: 'FAQ',
          label: 'FAQ'
        },
      ]
    };
  },
  computed: {
    ...mapGetters([
      'AUTH',
      'GET_STATUS_MOBILE_NAV',
      'GET_TOURNAMENT_GROUPS',
      'GET_SPONSOR_GAMES'
    ]),
  },
  methods: {
    ...mapActions([
      'SHOW_USER_AUTH_MODALS',
      'USER',
      'GET_SINGLE_USER',
      'LOGG_OUT',
      'SET_STATUS_MOBILE_NAV',
      'TOGGLE_STATUS_MOBILE_NAV',
      'SET_INACTIVE_MODAL_NOTIFICATIONS',
      'GET_DISCIPLINES',
      'SET_TOURNAMENT_GROUPS',
      'GET_SPONSOR_DISCIPLINES',
      'CLEAR_REGISTER_AFTER_AUTH_ACTION'
    ]),
    showUserAuthModals() {
      this.CLEAR_REGISTER_AFTER_AUTH_ACTION();

      // Показываем модалку с регистрацией
      this.SHOW_USER_AUTH_MODALS({
        isAuth: true,
        modal: 'password-auth'
      });
    },
    closeMobileNav() {
      this.SET_STATUS_MOBILE_NAV(false);
    },
    toggleStatusMobileNav() {
      this.SET_INACTIVE_MODAL_NOTIFICATIONS();
      this.TOGGLE_STATUS_MOBILE_NAV();
    },
    setLeftMargin() {
      this.$refs.disciplinesList.style.marginLeft = `${this.$refs.nav.getBoundingClientRect().left}px`;
    },
    autoMargin() {
      window.addEventListener('resize', () => this.setLeftMargin());
    },
    openList() {
      this.$refs.disciplinesList.style.maxHeight = `${this.$refs.disciplinesList.scrollHeight}px`;
      this.tournamentsListVisible = true;
    },
    closeList() {
      this.$refs.disciplinesList.style.maxHeight = `0px`;
      this.tournamentsListVisible = false;
    },
    async getData() {
      //TODO надо будет убрать отсюда, потому что в App.vue мы их уже получаем. Сделать прелоадер в шапке, пока они грузятся
      await this.GET_SPONSOR_DISCIPLINES();
      await this.SET_TOURNAMENT_GROUPS();
      this.dataReady = true;
    }
  },
  watch: {
    GET_STATUS_MOBILE_NAV: function () {
      // document.documentElement.style.overflow = this.GET_STATUS_MOBILE_NAV ? 'hidden' : 'auto';

      if (this.GET_STATUS_MOBILE_NAV) {
        document.body.classList.add('body_disableOverflow');
      } else {
        document.body.classList.remove('body_disableOverflow');
      }
    }
  },
  mounted() {
    this.getData();
    this.setLeftMargin();
    this.autoMargin();
    /*let showOrHideNav = new HideNavToMore('.header__nav .more', '.header__nav', '.header__nav .hidden__els', '.header__nav li', 1)
     showOrHideNav.start();*/
  }
};
</script>

<style scoped>
.filled__yellow {
  width: 200px;
  margin-right: 16px;
}
</style>
